
import { Options, Vue } from "vue-property-decorator";
import Validation from "@/components/Validation.vue";
import { namespace } from "s-vuex-class";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import GuestLayout from "@/layout/GuestLayout.vue";

const StudentPasswordModule = namespace("student-password");

@Options({
  components: {
    GuestLayout,
    DefaultLayout,
    Validation,
  },
  validations: {
    studentEmail: { required, email },
  },
})
export default class ForgotPassword extends Vue {
  public name = "ForgotPassword";

  public v$ = useVuelidate();

  public studentEmail = "";

  @StudentPasswordModule.Action("simplePost")
  public requestPasswordChangeAction: any;

  @StudentPasswordModule.Action("resetSave")
  public resetAction: any;

  @StudentPasswordModule.Getter("getError")
  public getError: any;

  @StudentPasswordModule.Getter("getDataItem")
  public requestPasswordChangeResult: any;

  @StudentPasswordModule.Getter("getIsLoading")
  public loading: any;

  public mounted(): void {
    this.resetAction();
    if (this.$route.query?.email) {
      this.studentEmail = this.$route.query.email.toString();
    }
  }

  get isRequestSent() {
    return !isUndefined(this.requestPasswordChangeResult) && !isNull(this.requestPasswordChangeResult);
  }

  public onSubmit(): void {
    //@ts-ignore
    this.v$.$touch();

    //@ts-ignore
    if (this.v$.$invalid) {
      return;
    }

    this.requestPasswordChangeAction({
      hideSuccessMessage: true,
      resource: "request-password-change",
      params: { studentEmail: this.studentEmail },
    });
  }
}
